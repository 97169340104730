<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.baslik }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="TEZGAH EKLE" entityName="bm_unite"></EntityHeader>
					<div class="card">
						<div class="grid">
							<div class="col">
								<div class="field p-fluid">
									<label for="indirimOrani">İndirim Oranı %</label>
									<InputNumber v-model="indirimOrani" v-tooltip.top="'En fazla: %' + maxIndirimorani" mode="decimal" prefix="%" :minFractionDigits="2" :maxFractionDigits="2" :min="0" :max="maxIndirimorani" placeholder="İndirim Oranı %" @input="changeIndirimorani($event)" />
								</div>
							</div>
							<div class="col">
								<div class="field p-fluid">
									<label for="navlunMasrafi">Navlun Masrafı</label>
									<InputNumber v-model="navlunMasrafi" mode="decimal" :minFractionDigits="0" :maxFractionDigits="0" :min="0" placeholder="Navlun Masrafı" />
								</div>
							</div>
							<div class="col">
								<div class="field p-fluid">
									<label for="istenilenToplam">İstenilen Toplam</label>
									<InputNumber v-model="istenilenToplam" mode="decimal" :minFractionDigits="0" :maxFractionDigits="0" :min="0" placeholder="Navlun Dahil" :disabled="indirimOrani == 0" />
								</div>
							</div>
							<div class="col-8">
								<div class="grid">
									<div class="col-12">
										<div class="grid dashboard">
											<div class="col">
												<div class="card overview-box flex flex-column pt-2 cyan-bgcolor solid-surface-text-color">
													<div class="flex align-items-center">
														<h6>İndirimsiz Toplam</h6>
													</div>
													<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
														<div class="flex flex-column">
															<span class="overview-status p0 fs-large">{{formatNumber(indirimsizToplam)}}</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col">
												<div class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color">
													<div class="flex align-items-center">
														<h6>İndirim Tutarı</h6>
													</div>
													<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
														<div class="flex flex-column">
															<span class="overview-status p-0 fs-large">{{formatNumber(getIndirimToplami())}}</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col">
												<div class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color">
													<div class="flex align-items-center">
														<h6>İndirim Oranı %</h6>
													</div>
													<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
														<div class="flex flex-column">
															<span class="overview-status p-0 fs-large">% {{formatNumber2(getIndirimOrani())}}</span>
														</div>
													</div>
												</div>
											</div>

											<div class="col">
												<div class="card overview-box flex flex-column pt-2 green-bgcolor solid-surface-text-color">
													<div class="flex align-items-center">
														<h6>Navlun Öncesi</h6>
													</div>
													<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
														<div class="flex flex-column">
															<span class="overview-status p-0 fs-large">{{formatNumber(genelToplam)}}</span>
														</div>
													</div>
												</div>
											</div>
											<div class="col">
												<div class="card overview-box flex flex-column pt-2 pink-bgcolor solid-surface-text-color">
													<div class="flex align-items-center">
														<h6>Genel Toplam</h6>
													</div>
													<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
														<div class="flex flex-column">
															<span class="overview-status p-0 fs-large">{{formatNumber(genelToplam + navlunMasrafi)}}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="card" v-if="urunAilesiId == '4503d13b-2415-dd11-ad90-000e0c3f151f'">
						<h4>Studer Ek Bilgiler</h4>
						<div class="grid">
							<div class="col-2">
								<div class="grid dashboard">
									<div class="col">
										<div class="card overview-box flex flex-column pt-2 lightblue-bgcolor solid-surface-text-color">
											<div class="flex align-items-center">
												<h6>Kurulum Tutarı (%{{kurulumBedelOrani}})</h6>
											</div>
											<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
												<div class="flex flex-column">
													<span class="overview-status p0 fs-large">{{formatNumber(studerKurulumTutari)}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-2">
								<div class="grid dashboard">
									<div class="col">
										<div class="card overview-box flex flex-column pt-2 lightblue-bgcolor solid-surface-text-color">
											<div class="flex align-items-center">
												<h6>Komisyon Tutarı (%{{komisyonBedelOrani}})</h6>
											</div>
											<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
												<div class="flex flex-column">
													<span class="overview-status p0 fs-large">{{formatNumber(studerKomisyonTutari)}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-2">
								<div class="grid dashboard">
									<div class="col">
										<div class="card overview-box flex flex-column pt-2 lightblue-bgcolor solid-surface-text-color">
											<div class="flex align-items-center">
												<h6>Ek Bedel</h6>
											</div>
											<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
												<div class="flex flex-column">
													<span class="overview-status p0 fs-large">{{formatNumber(ekBedel)}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-2">
								<div class="grid dashboard">
									<div class="col">
										<div class="card overview-box flex flex-column pt-2 blue-bgcolor solid-surface-text-color">
											<div class="flex align-items-center">
												<h6>EKLENTİLER TOPLAMI</h6>
											</div>
											<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
												<div class="flex flex-column">
													<span class="overview-status p0 fs-large">{{formatNumber(studerEkToplam)}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-2">
								<div class="grid dashboard">
									<div class="col">
										<div class="card overview-box flex flex-column pt-2 deeppurple-bgcolor solid-surface-text-color">
											<div class="flex align-items-center">
												<h6>Yeni Tezgah Tutarı</h6>
											</div>
											<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
												<div class="flex flex-column">
													<span class="overview-status p0 fs-large">{{formatNumber(studerYeniTezgahTutari)}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-2">
								<div class="grid dashboard">
									<div class="col">
										<div class="card overview-box flex flex-column pt-2 pink-bgcolor solid-surface-text-color">
											<div class="flex align-items-center">
												<h6>Yeni Genel Toplam</h6>
											</div>
											<div class="flex justify-content-between mt-0 flex-wrap" style="height:20px">
												<div class="flex flex-column">
													<span class="overview-status p0 fs-large">{{formatNumber(studerYeniGenelToplam)}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br/><br/>

					<DataTable
						:value="mainData"
						:rowHover="true"
						v-model:selection="selectedData" selectionMode="single" 
						dataKey="tempId" class="p-datatable-sm"
						responsiveLayout="scroll" 
						rowGroupMode="subheader" groupRowsBy="urunTuru"
						sortField="urunTuru" :sortOrder="-1"
						edit-mode="cell" @cell-edit-complete="onCellEditComplete"
						v-model:filters="filters2" :globalFilterFields="['urunKodu','urunName']">
						<template #header>
							<div class="flex justify-content-end">
								<span class="p-input-icon-left ">
									<i class="pi pi-search" />
									<InputText v-model="filters2['global'].value" placeholder="Anahtar Kelime" />
								</span>
							</div>
						</template>
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<template #groupheader="slotProps">
							<img :alt="slotProps.data.urunTuru" :src="getUrunTuru(slotProps.data.urunTuru)" width="32" style="vertical-align: middle" class="mr-3" />
							<span class="image-text fw-700">{{slotProps.data.urunTuru}}</span>
						</template>
						<Column field="urunTuru" header="urunTuru" :sortable="true" headerStyle="width:100px"></Column>
						<Column field="urunKodu" header="Ürün Kodu" :sortable="true" headerStyle="width:100px"></Column>
						<Column field="urunName" header="Ürün" :sortable="true" style="min-width:12rem">
							<template #body="slotProps">
								<span>{{slotProps.data.urunName}}</span>
								<span v-if="slotProps.data.urunDetayTanimi_tr" class="pi pi-info-circle ml-2 pink-color" v-tooltip.top="slotProps.data.urunDetayTanimi_tr"></span>
							</template>
							
						</Column>
						<Column field="birimFiyat" header="Birim Fiyat" :sortable="true" headerStyle="width:110px" bodyStyle="text-align: right">
							<template #body="slotProps">
								<span :class="styleAdet(slotProps.data)">{{formatNumber2(slotProps.data.birimFiyat)}} {{slotProps.data.paraBirimiSembol}}</span>
							</template>
						</Column>
						<Column field="adet" header="Adet" :sortable="true" headerStyle="width:90px" bodyStyle="text-align: center">
							<template #body="slotProps">
								<span :class="styleAdet(slotProps.data)">{{slotProps.data.adet}}</span>
							</template>
							<template #editor="{ data, field }">
								<InputNumber v-model="data[field]" :min="-1" inputStyle="width: 35px" showButtons :disabled="disabledAdet" />
							</template>
						</Column>
						<Column field="toplam" header="Toplam" :sortable="true" headerStyle="width:110px" bodyStyle="text-align: right; font-weight:bold">
							<template #body="slotProps">
								<span :class="styleAdet(slotProps.data)">{{formatNumber2(slotProps.data.toplam)}} {{slotProps.data.paraBirimiSembol}}</span>
							</template>
						</Column>
						<Column field="teklifeDahil" header="Teklife Dahil" headerStyle="width:100px" bodyStyle="text-align: right">
							<template #body="slotProps">
								<InputSwitch v-model="slotProps.data.teklifeDahil" v-if="isTeklifeDahilGorunsunmu(slotProps.data)" />
							</template>
							<template #editor="{ data, field }">
								<InputSwitch v-model="data[field]"/>
							</template>
						</Column>
						<Column :rowEditor="true" style="width:6rem; min-width:6rem" bodyStyle="text-align:center; color: red"></Column>
					</DataTable>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { FilterMatchMode } from 'primevue/api';

export default {
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 1084,
			mainData: [],
			selectedData: [],

			indirimOrani: null,
			maxIndirimorani: 0,
			navlunMasrafi: 0,
			istenilenToplam: null,
			paraBirimiId: null,

			//Stok Tezgah için adet alanının yönetilmesi
			disabledAdet: false,

			//STUDER'E ÖZEL HESAPLAMA
			kurulumBedelOrani: 0,
			komisyonBedelOrani: 0,
			ekBedel: 0,
			urunAilesiId: null,

			filters2: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'urunKodu': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'urunName': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },

			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
			],
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		this.LoadData();
	},
	computed: {
		quoteId() {
			return this.$route.params.quoteId;
		},
		productId() {
			return this.$route.params.productId;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		teklifVerenKisiSelected: {
			get: function () {
				if (this.mainData["teklifVerenKisiId"]) {
					return { "Value": this.mainData["teklifVerenKisiId"], "Name": this.mainData["teklifVerenKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["teklifVerenKisiId"] = null;
					this.mainData["teklifVerenKisiName"] = null;
				}
				else {
					this.mainData["teklifVerenKisiId"] = newValue.Value;
					this.mainData["teklifVerenKisiName"] = newValue.Name;
				}
			}
		},
		mainDataIslemYapilacak(){
			const filteredMainDataAdetliTeklifeDahilOlanlar = this.mainData.filter( x => 
				x.adet != 0
			);

			return filteredMainDataAdetliTeklifeDahilOlanlar;
		},
		genelToplam(){
			debugger;
			if (this.istenilenToplam > 0) {
				return this.istenilenToplam - this.navlunMasrafi;
			}

			if (this.mainData.length > 0) {
				const TeklifeDahilOlanlar = this.mainData.filter( x => x.teklifeDahil == true);

				if (TeklifeDahilOlanlar.length > 0) {
					let sum = TeklifeDahilOlanlar.reduce(function(acc, item) {
						return acc + item.toplam;
					}, 0);

					return sum;
				}
				else {
					return 0;
				}
			}
			else{
				return 0;
			}
		},
		indirimsizToplam(){
			if (this.mainData.length > 0) {
				const TeklifeDahilOlanlar = this.mainData.filter( x => x.teklifeDahil == true);

				if (TeklifeDahilOlanlar.length > 0) {
					let sum = TeklifeDahilOlanlar.reduce(function(acc, item) {
						return acc + (item.birimFiyat * item.adet);
					}, 0);

					return sum;
				}
				else {
					return 0;
				}
			}
			else{
				return 0;
			}
		},
		studerKurulumTutari(){
			return this.genelToplam * (this.kurulumBedelOrani / 100);
		},
		studerKomisyonTutari(){
			return this.genelToplam * (this.komisyonBedelOrani / 100);
		},
		studerEkToplam(){
			let genelToplam = this.genelToplam;
			let kurulumTutari = 0;
			let komisyonTutari = 0;

			if (this.kurulumBedelOrani) {
				kurulumTutari = genelToplam * (this.kurulumBedelOrani / 100);
			}

			if (this.komisyonBedelOrani) {
				komisyonTutari = genelToplam * (this.komisyonBedelOrani / 100);
			}

			return kurulumTutari + komisyonTutari + this.ekBedel;
		},
		studerYeniTezgahTutari(){
			const tezgahUrunu = this.mainData.filter( x => x.urunTuru == 'TEZGAH');
			let tezgahUrunFiyati = tezgahUrunu[0].toplam;

			return tezgahUrunFiyati + this.studerEkToplam
		},
		studerYeniGenelToplam(){
			const tezgahUrunu = this.mainData.filter( x => x.urunTuru == 'OPSİYONLAR' && x.teklifeDahil == true);
			let opsiyonlarToplami = tezgahUrunu.reduce(function (acc, obj) { return acc + obj.toplam; }, 0);
			

			return this.studerYeniTezgahTutari + opsiyonlarToplami;
		}
	},
	methods: {
		async OnSave(kapat) {
			debugger;
			if (this.istenilenToplam) {
				let indirimYapilacakToplamTutar = this.indirimsizToplam * (this.maxIndirimorani / 100);
				let minimumGenelToplam = (this.indirimsizToplam - indirimYapilacakToplamTutar) + this.navlunMasrafi;

				if (this.istenilenToplam < minimumGenelToplam) {
					this.$toast.add({severity:'error', summary: 'Hata', detail: 'İstenen Toplam Minimum Genel Toplam değerinden küçük olamaz!', life: 3500});
					return;
				}
			}

			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});
			
			debugger;
			let postData = {
				"teklifId" : this.quoteId,
				"navlunMasrafi": this.navlunMasrafi,
				"navlunOncesiTutar" : this.genelToplam,
				"istenilenToplam" : this.istenilenToplam,
				"indirimsizToplam" : this.indirimsizToplam,
				"girilenIndirimOrani" : this.indirimOrani,
				"yapilanIndirimTutari" : this.getIndirimToplami(),
				"indirimOrani" : this.getIndirimOrani(),
				"genelToplam" : this.genelToplam + this.navlunMasrafi,
				"paraBirimiId" : this.paraBirimiId,

				"kurulumTutari" : this.studerKurulumTutari,
				"komisyonTutari" : this.studerKomisyonTutari,
				"ekBedel" : this.ekBedel,
				"ekBedelliTezgahFiyati" : this.studerYeniTezgahTutari,
				"yeniGenelToplam" : this.studerYeniGenelToplam,


				"teklifUrunleri" : this.mainDataIslemYapilacak
			}

			console.log(postData);

			try {
				const response = await this.crmService.TeklifUrunleriCreateUpdate(postData);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});

						if (kapat) {
							if (window.opener) {
								window.opener.location.reload();
							}
							setTimeout(() => {
								window.close();
							}, 700);
						}
						else {
							this.LoadData();
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'TeklifUrunleriCreateUpdate', detail: error.message, life: 3500});
			}
			finally {
				loader.hide();
			}
		},
		async LoadData(){
			if (this.quoteId != null) {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});
				
				try {
					debugger;

					let data = await this.crmService.getTeklifTezgahEkle(this.quoteId);
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData;
					}

					console.log(this.mainData);

					if (data.ozet.length > 0) {
						this.istenilenToplam = data.ozet[0].istenilenToplam;
						this.indirimOrani = data.ozet[0].indirimOrani;
						this.navlunMasrafi = data.ozet[0].navlunMasrafi;
						this.paraBirimiId = data.ozet[0].paraBirimiId;

						this.kurulumBedelOrani = data.ozet[0].kurulumBedelOrani;
						this.komisyonBedelOrani = data.ozet[0].komisyonBedelOrani;
						this.ekBedel = data.ozet[0].ekBedel;
						this.urunAilesiId = data.ozet[0].urunAilesiId;

						if (data.ozet[0]["tezgahTuru"]) {
							if (data.ozet[0]["tezgahTuru"] == 930710002) { //Stok Tezgah ise
								this.disabledAdet = true;

								this.mainData.forEach(data => {
									data["yeniBirimFiyat"] = Math.round(data.birimFiyat);
									data["toplam"] = Math.round(data.birimFiyat * data.adet);
								});
							}
						}

						setTimeout(() => {
							this.changeIndirimorani({'value': data.ozet[0].indirimOrani});
						}, 500);


						let isSeviyeA = (this.profileData.guvenlikSeviyeleri.indexOf("Seviye A") > -1);
						let isSeviyeB = (this.profileData.guvenlikSeviyeleri.indexOf("Seviye B") > -1);
						let isSeviyeC = (this.profileData.guvenlikSeviyeleri.indexOf("Seviye C") > -1);

						if (isSeviyeA) {
							this.maxIndirimorani = data.ozet[0].indirimA;
						}
						else if (isSeviyeB) {
							this.maxIndirimorani = data.ozet[0].indirimB;
						}
						else if (isSeviyeC){
							this.maxIndirimorani = data.ozet[0].indirimC;
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
		},
		getUrunTuru(urunTuru){
			if (urunTuru == 'TEZGAH') {
				return "layout/images/entityImages/product.png";
			}
			else {
				return "layout/images/entityImages/bm_opsiyonpaketi.png";
			}
		},
		onCellEditComplete(event) {
			debugger;
            let { data, newValue, field } = event;

			if (field == 'adet') {
				debugger;
				if (newValue == 0) {
					if (data["kaldirilabilir"] != undefined) {
						if (data["kaldirilabilir"] == false) {
							this.$toast.add({severity:'error', summary: 'Hata', detail: 'Bu opsiyon kaldırılamaz!', life: 3500});
							return false;
						}
					}
				}

				if (newValue == -1 && data["eksiDeger"] == false) {
					this.$toast.add({severity:'error', summary: 'Hata', detail: 'Bu opsiyon Eksi Değerli olamaz!', life: 3500});
					return false;
				}

				if (newValue > 0 && data["eksiDeger"] == true) {
					this.$toast.add({severity:'error', summary: 'Hata', detail: 'Eksi Değerli opsiyonların adetleri 0 dan büyük olamaz!', life: 3500});
					return false;
				}

				data[field] = newValue;
				if (newValue == 0) {
					data["yeniBirimFiyat"] = data.birimFiyat;
					data["toplam"] = 0;
					data["teklifeDahil"] = false;
				}
				else {
					if (data.indirimYapilabilir && data.birimFiyat > 0) { //İNDİRİM YAPILABİLİR
						if (data.ozelIndirim == 0 && this.indirimOrani != null) {
							data.ozelIndirim = this.indirimOrani;
						}

						let indirimOrani = data.ozelIndirim / 100;
						let indirimTutari = data.birimFiyat * indirimOrani;
						let indirimliFiyat = data.birimFiyat - indirimTutari;

						data["yeniBirimFiyat"] = indirimliFiyat;
						data["toplam"] = indirimliFiyat * newValue;
						
						data["teklifeDahil"] = true;
					}
					else { //İNDİRİM YAPILAMAZ
						data["yeniBirimFiyat"] = data.birimFiyat;
						data["toplam"] = data.birimFiyat * newValue;
						data["teklifeDahil"] = true;
					}
				}
			}
			else if(field == 'teklifeDahil'){
				debugger;
				if (data.adet == 0) {
					data[field] = false;
					this.$toast.add({ severity: 'warn', summary: 'Uyarı', detail: 'Teklife dahil edebilmek için ADET dolu olmalı!', life: 3500 });
				}
				else {
					data[field] = newValue;
				}
				
				/*
				if (data["kaldirilabilir"] != undefined) {
					if (data["kaldirilabilir"] == false) {
						event.preventDefault();
						this.$toast.add({severity:'error', summary: 'Hata', detail: 'Bu opsiyon kaldırılamaz!', life: 3500});
					}
				}
				else {
					
				}
				*/
			}
        },
		isTeklifeDahilGorunsunmu(item){
			debugger;
			if (item["kaldirilabilir"] == undefined) {
				return true;
			}
			else {
				if (item.kaldirilabilir) {
					return true;
				}
				else {
					return false;
				}
			}
		},
		changeIndirimorani(event){
			debugger;
			const filteredMainDataAdetliTeklifeDahilOlanlar = this.mainData.filter( x => 
				x.adet > 0 && x.teklifeDahil == true && x.birimFiyat > 0 && x.indirimYapilabilir == true
			);

			if (event.value) {
				let indirimOrani = 0;
				if (event.value > this.maxIndirimorani) {
					//indirimOrani = this.maxIndirimorani / 100;
					this.$toast.add({ severity: 'warn', summary: 'Uyarı', detail: 'Tanımlı İndirim oranından daha fazla İNDİRİM giremezsiniz!', life: 3500 });
				}
				else {
					indirimOrani = event.value / 100;
				}

				filteredMainDataAdetliTeklifeDahilOlanlar.forEach(data => {
					if (data.ozelIndirim == 0 && indirimOrani != null) {
						data.ozelIndirim = indirimOrani;
					}

					let indirimTutari = data.birimFiyat * indirimOrani;
					let indirimliFiyat = data.birimFiyat - indirimTutari;

					data["ozelIndirim"] = indirimOrani * 100;
					data["yeniBirimFiyat"] = Math.round(indirimliFiyat);
					data["toplam"] = Math.round(indirimliFiyat * data.adet);
					data["teklifeDahil"] = true;
					
					/*
					if (data.QuoteDetailId != '00000000-0000-0000-0000-000000000000' && data.ozelIndirim == 0) {
						console.log('Teklif ürünü update ve indirimi 0')
					}
					else {
						
					}
					*/
				});
			}
			else{
				filteredMainDataAdetliTeklifeDahilOlanlar.forEach(data => {
					data["ozelIndirim"] = 0;
					data["yeniBirimFiyat"] = Math.round(data.birimFiyat);
					data["toplam"] = Math.round(data.birimFiyat * data.adet);
				});
			}
		},
		getIndirimToplami(){
			return Math.round(this.indirimsizToplam) - Math.round(this.genelToplam);
		},
		getIndirimOrani(){
			if (this.genelToplam == 0 || this.indirimsizToplam == 0) {
				return 0;
			}
			else {
				let indirimOrani = 100 - (this.genelToplam * 100 / this.indirimsizToplam);
				return Math.round((indirimOrani + Number.EPSILON) * 100) / 100
			}
		},
		styleAdet(item){

			if (item.eksiDeger == true) {
				return "kirmiziYazi";
			}
			else {
				if (item.adet > 0) {
					return "adet-badge dolu"
				}
				else if(item.adet < 0) {
					return "adet-badge eksiDeger"
				}
				else {
					return ""
				}
			}
		},
		styleIndirim(data){
			if (data.indirimYapilabilir == true & data.adet > 0) {
				return "adet-badge indirim";
			}else if (data.indirimYapilabilir == false & data.adet > 0) {
				return "adet-badge dolu";
			}else if (data.adet < 0) {
				return "adet-badge eksiDeger";
			}
			else {
				return "";
			}
		},
		formatNumber(value) {
			if (value != undefined) {
				return value.toLocaleString('tr-TR') + ' ' + this.getCurrency();
			}
		},
		formatNumber2(value) {
			if (value != undefined) {
				return value.toLocaleString('tr-TR');
			}
		},
		getCurrency() {
			if (this.mainData.length > 0) {
				let paraBirimi = this.mainData[0].paraBirimiSembol;
				return paraBirimi;
			}
			else {
				return '';
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Siparişler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
}
</script>

<style lang="scss" scoped>
	::v-deep(.p-rowgroup-header) {
		background-color: #ecf0f1 !important;
	}

	.kirmiziYazi {
		font-weight: bold;
		color: #C63737;
	}

	.adet-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: .3px;

		&.dolu {
			background: #B3E5FC;
			color: #23547B;
		}

		&.indirim {
			background: #FFCDD2;
			color: #C63737;
		}

		&.eksiDeger {
			background: #C63737;
			color: #FFFFFF;
		}
	}

	.parabirimi-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.dolar {
			background: #C8E6C9;
			color: #256029;
		}

		&.yen {
			background: #FFCDD2;
			color: #C63737;
		}

		&.bosta1 {
			background: #FEEDAF;
			color: #8A5340;
		}

		&.euro {
			background: #B3E5FC;
			color: #23547B;
		}

		&.isviçrefrankı {
			background: #ECCFFF;
			color: #694382;
		}

		&.bosta2 {
			background: #FFD8B2;
			color: #805B36;
		}
	}
</style>
